<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div>

        <a-row :gutter="24" class="px-15" style="margin-top: 0px;">
            <a-col :span="24" :md="16" :lg="16" :xl="16" class="mb-24">
                <a-badge 
                    v-for="option in filterItems"
                    :key="option.uuid"
                    style="font-size: 14px !important; margin: 2px 3px; padding: 5px;" 
                    :count="`${option.name}    ${option.icon}`" 
                    @click="onSelectFilterItem(option)"
                    :number-style="pagination.filter == option.uuid ? 
                        {backgroundColor: '#734EBF !important', color: '#FFFFFF', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'} : 
                        {border: '1px solid #734EBF !important', color: '#734EBF', backgroundColor: 'transparent !important', paddingTop: '5px', paddingRight: '18px', paddingLeft: '18px', height: '32px', fontSize: '14px'}"/>

                <a-button
                    id="textBtn"
                    type="text"
                    class="ml-15"
                    size="small"
                    @click="toCreateCategoriesPage"
                    style="border-radius: 20px;">
                    Manage Categories
                    <font-awesome-icon icon="fa-solid fa-gear" class="ml-10" style=""/>
                </a-button>
            </a-col>

			<a-col :span="24" :md="8" :lg="8" :xl="8" class="mt-10 text-right">
                <a-button
                    type="primary"
                    class="ml-15"
                    size="small"
                    @click="toCreateClassPage"
                    style="border-radius: 20px;">
                    Create Class 
                    <a-icon type="plus" theme="outlined" />
                </a-button>
			</a-col>
		</a-row>

		<div v-if="!loadingData" class="mb-20 mt-20">

			<a-row :gutter="24" class="px-20">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mb-24 pb-10">
					<a-card>
                        <a-row :gutter="24" class="mb-0 pb-0 pt-5 px-10">
                            <a-col :span="24" :md="14" :lg="14" :xl="14" class="mb-0 pb-0">
                                <h6 style="font-size: 18px;">{{ classes.length }} Group Classes</h6>
                            </a-col>
                            <a-col :span="24" :md="10" :lg="10" :xl="10" class="mb-0 pb-0 text-right">
                                <h6 style="font-weight: normal;"><small>TZS</small> {{ classEarnings.toLocaleString() }}/=</h6>
                            </a-col>
                        </a-row>
                        
                        <a-row :gutter="24" class="mb-0 pb-0 mt-15">
                            <a-col :span="24" :md="24" :lg="24" :xl="24" class="mb-0 pb-0">
                                <a-table
                                    class="ant-table-striped"
                                    :columns="columns" 
									:data-source="classes"
									:loading="loadingData" 
									:pagination="true" 
									:rowKey="record => record.uuid"
									width="100%" 
									style="width: 100%;"
                                    :scroll="{ x: windowWidth < 660 ? 1300 : 0 }">

                                        <template slot="s_no" slot-scope="s_no, record, index">
                                            {{ (pagination.currentPage - 1) * pagination.perPage + index + 1 }}
                                        </template>

                                        <template slot="classTitle" slot-scope="row"> 
                                            <a-avatar :src="row.thumbnail" shape="square" class="mr-5"/>
                                            {{ formatName(row.name.length > 35 ? `${row.name.substring(0, 35)}...` : row.name ) }}
                                        </template>

                                        <template slot="instructor" slot-scope="row"> 
                                            <!-- <a-avatar :src="row.thumbnail" class="mr-5"/> -->
                                            <!-- {{ `${row.tutor.firstName} ${row.tutor.lastName}` }} -->
                                            {{ row.coTutors.length == 0 ? row.coTutors[0] :  '-'}}
                                        </template>

                                        <template slot="students" slot-scope="row"> 
                                            <!-- {{ row.studentNo }} -->
                                            {{ row.members.length == 0 ? row.members.length : row.members.length - 1 }}
                                        </template>

                                        <template slot="price" slot-scope="row"> 
                                            {{ row.price != null && row.price > 0 ? `${row.price.toLocaleString()}/=` : 'Free' }}
                                        </template>

                                        <template slot="action" slot-scope="row"> 
                                            <a-button
                                                id="textBtn"
                                                type="text"
                                                class="ml-15"
                                                size="small"
                                                @click="viewClass(row)"
                                                style="border-radius: 20px;">
                                                
                                                <a-icon type="eye" theme="outlined" />
                                            </a-button>
                                        </template>
                                        
                                </a-table>
                            </a-col>
                        </a-row>
                        
                    </a-card>
				</a-col>

			</a-row>

		</div>

		<div v-if="loadingData">

			<a-row type="flex" justify="space-around" align="middle" class="px-20" style="min-height: 60vh">

				<a-col :span="24" :md="24" :lg="24" class="text-center  justify-items-center">

					<a-spin class="text-primary" size="large"/>

				</a-col>

			</a-row>
		</div>


        <!-- DETAILS DRAWER  -->
        <a-drawer
            title="Class Details"
            placement="right"
            :closable="true"
            :visible="classDetails.showDrawer"
            @close="onCloseDrawer"
            width="560">
            
            <a-row :gutter="24">
                <a-col :span="24">

                    <a-row :gutter="24">
                        <a-col :span="24">
                            <h5>{{ classDetails.title }}</h5>
                        </a-col>

                        <a-col :span="24">
                            <img id="classImg" width="100%" height="250px" :src="classDetails.thumbnail" alt="">
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Instructor</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <!-- {{ classDetails.instructor }} -->
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Start Date</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ classDetails.startDate }}
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Members</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ classDetails.members !=  null && classDetails.members > 0 ? classDetails.members - 1 : classDetails.members  }}
                        </a-col>
                    </a-row>

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Completed the course</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ classDetails.courseCompletition }}
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Slots</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ classDetails.slots }}
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Price</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <span style="font-size: 10px; font-weight: 600;" v-if="classDetails.price != 0 && classDetails.price != null">TZS </span> {{ classDetails.price != 0 && classDetails.price != null ? classDetails.price.toLocaleString() : 'Free' }}
                        </a-col>
                    </a-row>

                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Total Revenue</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <span style="font-size: 10px; font-weight: 600;">TZS </span> {{ classDetails.totalRevenue }}
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Rating</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ classDetails.rating }}
                        </a-col>
                    </a-row>
                    <!-- <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Education System</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ classDetails.educationSystem.name }}
                        </a-col>
                    </a-row> -->

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Education Level</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            {{ classDetails.educationLevel.name }}
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Grades</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-badge 
                                v-for="option in classDetails.grades"
                                :key="option.uuid"
                                style="font-size: 12px !important;" 
                                :count="`${option.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>


                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Subjects</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-badge 
                                v-for="option in classDetails.subjects"
                                :key="option.uuid"
                                style="font-size: 12px !important;" 
                                :count="`${option.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 px-10">
                        <a-col :span="12">
                            <label><strong>Category</strong></label>
                        </a-col>

                        <a-col :span="12" class="text-right">
                            <a-button
                                id="textBtn"
                                type="text"
                                class="ml-15"
                                size="small"
                                v-if="classDetails.category == null"
                                @click="onAddingCategory"
                                style="border-radius: 20px;">
                                <a-icon type="plus" theme="outlined" />
                                {{ 'Add' }}
                            </a-button>

                            <a-button
                                id="textBtn"
                                type="text"
                                class="ml-15"
                                size="small"
                                v-if="classDetails.category != null"
                                @click="onEditingCategory"
                                style="border-radius: 20px;">
                                <a-icon type="edit" theme="outlined" />
                                {{ 'Edit' }}
                            </a-button>
                            
                            <a-badge 
                                v-if="classDetails.category != null"
                                style="font-size: 12px !important;" 
                                :count="`${classDetails.category.name}`" 
                                :number-style="{backgroundColor: '#DBE2ED !important', color: '#414141', paddingTop: '2px', paddingRight: '10px', paddingLeft: '10px', height: '25px', fontSize: '12px'}"/>
                        </a-col>
                    </a-row>

                    <a-row :gutter="24" class="mt-20 pt-20 px-10">
                        <a-col :span="24" class="text-center mt-20">
                            <a-button
                                type="primary"
                                class="ml-15"
                                size="small"
                                @click="toManageClass"
                                style="border-radius: 20px;">
                                <a-icon type="setting" theme="outlined" />
                                Manage Class 
                            </a-button>
                        </a-col>
                    </a-row>

                </a-col>
            </a-row>
        </a-drawer>


        <!-- FORM MODAL -->
		<a-modal v-model="category.showModal" :footer="null" :width="480">
			<h4 class="text-center">{{  category.isCreate ? 'Add Class Category' : 'Edit Class Category'}}</h4>
			<a-form-model
				class="login-form"
				@submit.prevent="submitCategory"
				:hideRequiredMark="true">

				<a-form-item class="mb-10" label="Category" :colon="false">
					<a-select 
                        v-model="category.category"
                        style="width: 100%;" 
                        placeholder="Select Category">
                        <a-select-option v-for="option in categories"
                            :value="option.uuid"
                            :key="option.uuid">
                            {{ option.name }}
                        </a-select-option>
                    </a-select>
				</a-form-item>
                

				<a-form-item>
					<a-button type="primary" :disabled="category.category == null" :loading="category.loading" block html-type="submit" class="login-form-button text-white mt-20">
						{{ category.isCreate ? 'Save' : 'Update' }}
					</a-button>
				</a-form-item>

			</a-form-model>
			
		</a-modal>

	</div>
</template>

<script>
	import {DoubleBounce} from 'vue-loading-spinner'
	import WidgetCounter from '../../components/Widgets/WidgetCounter';
    import { notification } from 'ant-design-vue';


    const columns = [
        {
            title: 'S/n',
            scopedSlots: { customRender: 's_no' },
            width: 50
        },
        {
            title: 'Class title',
            scopedSlots: { customRender: 'classTitle' },
            width: 200
        },
        {
            title: 'Instructor',
            scopedSlots: { customRender: 'instructor' },
            width: 200
        },
        {
            title: '# of Students',
            scopedSlots: { customRender: 'students' },
            key: 'sudents',
            width: 100
        },
        {
            title: 'Price',
            scopedSlots: { customRender: 'price' },
            width: 100
        },
        {
            title: 'View Class',
            scopedSlots: { customRender: 'action' },
            width: 100
        },
    ];

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
		},

		data() {
			return {
                windowWidth: window.innerWidth,
				loadingData: false,

				classes: [],
                categories: [],
                classEarnings: 0,
                columns,
				currentPage: 1,
                pagination: {
					search: '',
                    filter: 'my-classes',
                    filterType: 'static',
                    perPage: 13,
                    currentPage: 1,
                    totalPages: 1,
                    perPageOptions: [10, 20, 30, 50],
                    total: 0
                },

				slider: {
                    max: 40000,
                    min: 0,
                    step: 500
                },

                filterItems: [
                    { uuid: 'my-classes', name: 'All Classes ', icon: ' 🎓', type: 'static'},
                    { uuid: 'archived', name: 'Archived ', icon: ' 🗃️', type: 'static'}
                ],

                filter: {
                    loading: false,
                    showModal: false,
                    isActive: false,
                    type: null,
                    price: 20000,
                    tutorRating: 3,
                    startTime: null,
                },

                institution: {},

                classDetails: {
                    uuid: null,
                    title: null,
                    thumbnail: null,
                    instructor: null,
                    startDate: null,
                    members: [],
                    courseCompletition: null,
                    educationLevel: {},
                    educationSystem: {},
                    subjects: [],
                    category: {},
                    slots: null,
                    price: null,
                    totalRevenue: null,
                    rating: null,
                    label: null,
                    showDrawer: false,
                },

                category: {
                    category: null,
                    showModal: false,
                    isCreate: true,
                    loading:false,
                },

      		}
		},
		watch: {
            // pagination: {
				
            //     handler() {
            //         // this.getInstitutionClasses();
			// 		console.log(this.pagination.currentPage)
            //     },
            // },
			currentPage: {
                handler() {
					console.log(this.currentPage)
					this.pagination.currentPage = this.currentPage;
					this.getInstitutionClasses();
                },
            },
            deep: true,
        },

		beforeDestroy() { 
			window.removeEventListener('resize', this.onResize); 
		},
		mounted () {
            this.$nextTick(() => {
				window.addEventListener('resize', this.onResize);
			})

			let breadcrumbs = [
				{ title: 'Classes', url: '/institutions/classes', isActive: true }
			];
			
			this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
            this.$root.$refs.InstitutionClasses = this;
            this.initializeFilter();
            this.getCategories();
            this.getClassesReport();
            this.getInstitutionClasses();
		},
		methods: {

            onResize() {
				this.windowWidth = window.innerWidth
			},

			formatName(name) {
				let formattedName = name != null ? `${name[0].toUpperCase()}${name.substring(1).toLowerCase()}` : name;
                return formattedName;
            },

            async toCreateCategoriesPage() {
                await localStorage.setItem('previous_route', this.$route.path);

                this.$router.push('/institutions/categories')
            },

            toCreateClassPage() {
                this.$router.push('/institutions/my-classes/create-new-class')
            },

            toManageClass() {
                this.$router.push(`/institutions/my-classes/view/${this.classDetails.uuid}`)
            },

			submitSearch(search) {
                this.pagination.search = search;
				this.pagination.currentPage = 1;
				this.getInstitutionClasses();
			},

			cancelSearch() {
				this.pagination.search = '';
				this.getInstitutionClasses();
			},

			launchFilter() {
                this.filter.showModal = true;
            },

            clearFilter() {
                this.filter.isActive = false;
                this.filter.showModal = false;
                this.getInstitutionClasses();
            },

            submitFilter() {
                this.filter.isActive = true;
                this.filter.showModal = false;
                this.getInstitutionClasses();
            },

            async initializeFilter() {
                let filter = JSON.stringify(await localStorage.getItem('filter'))

                if(filter != null) {
                    if(filter.navigate == true) {
                        filter.navigate = false;
                        await localStorage.setItem('filter', JSON.stringify(filter))
                        
                        this.filter.price = filter.price;
                        this.filter.tutorRating = filter.tutorRating;
                        this.filter.startTime = filter.startTime;
                    }
                }
            },

            onSelectFilterItem(item) {
                this.pagination.filter = item.uuid;
                this.pagination.filterType = item.type;

                this.getInstitutionClasses();
            },


			async getInstitutionClasses() {

				this.loadingData = true;

                let url = '';

                let institutionInfo = await localStorage.getItem("institution_details")

				let institution = institutionInfo != null ? JSON.parse(institutionInfo) : {}

                if(this.pagination.filterType == 'custom') {

                    const { currentPage, perPage, search } = this.pagination;
                    
                    if(search != null && search.length > 0) {                       
                        url = `${this.$BACKEND_URL}/classes?institution=${institution.uuid}&category=${this.pagination.filter}&search=${search}&page=${currentPage}&size=${perPage}&populate=true`;
                    }else{
                        url = `${this.$BACKEND_URL}/classes?institution=${institution.uuid}&category=${this.pagination.filter}&page=${currentPage}&size=${perPage}&populate=true`;
                    }

                }


                if(this.pagination.filter == 'my-classes') {

                    const { currentPage, perPage, search } = this.pagination;

                    if(search != null && search.length > 0) {                       
                        url = `${this.$BACKEND_URL}/classes?institution=${institution.uuid}&search=${search}&page=${currentPage}&size=${perPage}&populate=true`;
                    }else{
                        url = `${this.$BACKEND_URL}/classes?institution=${institution.uuid}&page=${currentPage}&size=${perPage}&populate=true`;
                    }
                }


                if(this.pagination.filter == 'archived') {

                    const currentPage = 1;
                    const perPage = 1000;
                    let { search } = this.pagination;

                    if(search != null && search.length > 0) {
                        url = `${this.$BACKEND_URL}/classes/archived?institution=${institution.uuid}&search=${search}&page=${currentPage}&size=${perPage}&populate=true`;
                    }else{
                        url = `${this.$BACKEND_URL}/classes/archived?institution=${institution.uuid}&page=${currentPage}&size=${perPage}&populate=true`;
                    }
                }
                

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
						this.classes = response.data.data;
						this.pagination.totalPages = response.data.pagination != null ? response.data.pagination.totalPages : 1;
                        this.pagination.total = response.data.pagination != null ? response.data.pagination.totalItems : 0;
                        this.pagination.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
						this.currentPage = response.data.pagination != null ? parseInt(response.data.pagination.currentPage) : 1;
                    }

                    this.getClassesReport(institution.uuid)
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getCategories() {

				this.loadingData = true;

                let institutionInfo = await localStorage.getItem("institution_details")

				let institution = institutionInfo != null ? JSON.parse(institutionInfo) : {}

                this.institution = institution;

                const currentPage = 1
                const perPage = 1000;
                  
                let url = `${this.$BACKEND_URL}/categories?creator=${institution.uuid}&page=${currentPage}&size=${perPage}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        
                        this.categories = response.data.data;

						response.data.data.forEach(category => {
                            category.icon = ' 🧩';
                            category.type = 'custom'
                            this.filterItems.push(category)
                        })
						
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            async getClassesReport(institution) {

				this.loadingData = true;

                let userInfo = await localStorage.getItem("user_details")

				let userDetails = userInfo != null ? JSON.parse(userInfo) : {}
                // uuid=${userDetails.uuid}&
                let url = `${this.$BACKEND_URL}/payments/reports?populate=true&uuid=${institution}&isForInstitution=true`;
                
                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200) {
                        if(response.data.data != null && response.data.data.length > 0) {
                            response.data.data.forEach(element => {

                                if(element.price != null) {
                                    this.totalEarning += element.price;
                                }

                                if(element.isClazz && element.price != null) {
                                    this.classEarnings += element.price;
                                    // this.classes.push(element);
                                }
                            });
                        }
                    }
					
					this.loadingData = false;
					
                }).catch(async(error) => {
					
					this.loadingData = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },


            onAddingCategory() {
                this.category.category = undefined;
                this.category.loading = false;
                this.category.showModal = true;
            },

            onEditingCategory() {
                this.category.category = this.classDetails.category.uuid;
                this.category.isCreate = false;
                this.category.loading = false;
                this.category.showModal = true;
            },


            submitCategory() {

                this.category.loading = true;

				let url = `${this.$BACKEND_URL}/classes/${this.classDetails.uuid}`;

				this.$AXIOS.patch(url, this.category).then(async(response) => {

					this.notify('Category have been saved successfully', 'success')

					this.category.showModal = false;
                    this.category.loading = false;

                    this.onCloseDrawer();

                    this.getInstitutionClasses();

				}).catch(async(error) => {

					this.category.loading = false;

                    console.log(error)

					if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

						this.$router.push(`/sign-in`);
					}
				});
            },


            viewClass(clazz) {
                this.classDetails.uuid = clazz.uuid;
                this.classDetails.title = clazz.name;
                this.classDetails.thumbnail = clazz.thumbnail;
                this.classDetails.instructor = clazz.coTutors;
                this.classDetails.startDate = clazz.dateTime != null ? this.$Moment(clazz.dateTime).format('DD MMM YYYY') : clazz.dateTime;
                this.classDetails.members = clazz.members.length;
                this.classDetails.educationSystem = clazz.educationSystems.length > 0 ? clazz.educationSystems[0] : {};
                this.classDetails.educationLevel = clazz.educationLevels.length > 0 ? clazz.educationLevels[0] : {};
                this.classDetails.grades = clazz.educationGrades;
                this.classDetails.subjects = clazz.subjects;
                this.classDetails.category = clazz.category;
                // this.classDetails.courseCompletition = '4';
                this.classDetails.slots = clazz.numOfSlots;
                this.classDetails.price = clazz.price;
                // this.classDetails.totalRevenue = clazz.price;
                this.classDetails.rating = clazz.rating != null ? clazz.rating : 5;
                this.classDetails.label = null
                this.classDetails.showDrawer = true;

                // this.category.category = clazz.ca
            },

            onCloseDrawer() {
                this.classDetails.showDrawer = false;
                this.classDetails.uuid = null;
                this.classDetails.title = null;
                this.classDetails.thumbnail = null;
                this.classDetails.instructor = null;
                this.classDetails.startDate = null;
                this.classDetails.members = null;
                this.classDetails.courseCompletition = null;
                this.classDetails.price = null;
                this.classDetails.totalRevenue = null;
                this.classDetails.rating = null;
                this.classDetails.label = null;
            },

            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
            },

		}
	})

</script>

<style>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

.ant-pagination-item-active a {
    color: #FFFFFF !important;
	background-color: #734EBF;
}

#classImg {
	border-radius: 5px;
}

a:hover {
    color: inherit !important;
}
</style>